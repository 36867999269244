import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const PublicRoute = ({ component: Component }) => {
  const token = localStorage.getItem('authToken');
  return token ? <Navigate to="/dashboard" /> : <Component />;
};

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PublicRoute;
