import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import axios from "axios"; // Import axios for API calls

function Illustration() {
  const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://api.carolesplacecafe.com/api/token", {
        username: username,
        password: password,
      });
      const { token } = response.data;
      localStorage.setItem("authToken", token); // Store token in local storage
      navigate("/dashboard"); // Redirect to dashboard
    } catch (err) {
      setError("Invalid credentials");
    }
  };

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your username and password to sign in"
    >
      <ArgonBox component="form" role="form" textAlign="center" onSubmit={handleSignIn}>
        <ArgonBox mb={2}>
          <ArgonInput
            type="text"
            placeholder="Username"
            size="large"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </ArgonBox>
        <ArgonBox mb={2}>
          <ArgonInput
            type="password"
            placeholder="Password"
            size="large"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </ArgonBox>
        {error && (
          <ArgonBox mb={2} bgcolor="#ffe6e6" borderRadius="5px" p={2}>
            <ArgonTypography color="error" fontWeight="bold">
              {error}
            </ArgonTypography>
          </ArgonBox>
        )}
        <ArgonBox mt={4} mb={1}>
          <ArgonButton color="info" size="large" fullWidth type="submit">
            Sign In
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
    </IllustrationLayout>
  );
}

export default Illustration;
