import Products from "layouts/products";
import SignIn from "layouts/authentication/sign-in";
import Dashboard from "layouts/dashboard";
import ProtectedRoute from "components/ProtectedRoute";
import PublicRoute from "components/PublicRoute";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

const routes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <ProtectedRoute component={Dashboard} />,
  },
  {
    type: "route",
    name: "Products",
    key: "products",
    route: "/products",
    icon: (
      <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-calendar-grid-58" />
    ),
    component: <ProtectedRoute component={Products} />,
  },
  {
    type: "route",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    component: <PublicRoute component={SignIn} />,
  },
];

export default routes;
