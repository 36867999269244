import React, { useState, useEffect } from "react";
import axios from "axios";
import CountUp from "react-countup";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add"; // Import the AddIcon for the button
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard"; 

const columns = [
  { name: "name", align: "left" },
  { name: "price", align: "center" },
  { name: "stock", align: "center" },
  { name: "replenishCost", align: "center" },
  { name: "actions", align: "center" },
];

function getTintBasedOnStock(stock, goodStock) {
  const ratio = stock / goodStock;
  if (ratio > 0.75) return "rgba(76, 175, 80, 0.1)";
  if (ratio > 0.5) return "rgba(255, 193, 7, 0.1)";
  return "rgba(244, 67, 54, 0.1)";
}

function getReplenishCostColor(replenishCost, averageCost) {
  if (replenishCost === 0) return "success";
  if (replenishCost <= averageCost) return "warning";
  return "error";
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const colors = [
  "#FF6633", "#FFB399", "#FF33FF", "#FFFF99", "#00B3E6", 
  "#E6B333", "#3366E6", "#999966", "#99FF99", "#B34D4D",
];

function Products() {
  const [productRows, setProductRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false); // State for Add Product Modal
  const [productToDelete, setProductToDelete] = useState(null);
  const [currentProduct, setCurrentProduct] = useState({ name: "", price: 0, good_stock: 0, history: [] });
  const [newProduct, setNewProduct] = useState({ name: "", price: 0, good_stock: 0, current_stock: 0 });

  const [lowStockItems, setLowStockItems] = useState(0);
  const [totalReplenishCost, setTotalReplenishCost] = useState(0);

  const fetchData = () => {
    setLoading(true);
    const token = localStorage.getItem("authToken"); // Retrieve token from localStorage
    axios
      .get("https://api.carolesplacecafe.com/api/products", {
        headers: { Authorization: token },
      })
      .then((response) => {
        const shuffledColors = shuffleArray([...colors]);
        let lowStockCount = 0;
        let replenishCostSum = 0;

        const products = response.data.map((product, index) => {
          const latestQuantity = product.history?.[product.history.length - 1]?.quantity || 0;
          const tint = getTintBasedOnStock(latestQuantity, product.good_stock);
          const latestPrice = product.price[Object.keys(product.price).sort().pop()];

          const replenishAmount = product.good_stock - latestQuantity;
          const replenishCost = replenishAmount > 0 ? replenishAmount * latestPrice : 0;

          const averageCost = product.good_stock * latestPrice;

          if (replenishAmount > 0) {
            if (tint === "rgba(244, 67, 54, 0.1)") {
              lowStockCount += 1;
            }
            replenishCostSum += replenishCost;
          }

          return {
            id: product.name,
            name: (
              <ArgonBox display="flex" alignItems="center">
                <ArgonBox
                  width="10px"
                  height="10px"
                  borderRadius="50%"
                  bgColor={shuffledColors[index]}
                  mr={1}
                />
                {product.name}
              </ArgonBox>
            ),
            price: `$${latestPrice.toFixed(2)}`,
            stock: (
              <ArgonBox bgColor={tint} borderRadius="5px" px={1}>
                {latestQuantity}
              </ArgonBox>
            ),
            replenishCost: (
              <ArgonTypography
                variant="button"
                color={getReplenishCostColor(replenishCost, averageCost)}
                fontWeight="bold"
              >
                ${replenishCost.toFixed(2)}
              </ArgonTypography>
            ),
            actions: (
              <ArgonBox display="flex" justifyContent="center">
                <IconButton size="small" color="primary" onClick={() => handleEditClick(product)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => handleDeleteClick(product.name)}
                >
                  <DeleteIcon />
                </IconButton>
              </ArgonBox>
            ),
          };
        });

        setProductRows(products);
        setLowStockItems(lowStockCount);
        setTotalReplenishCost(replenishCostSum);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteClick = (productName) => {
    setProductToDelete(productName);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = () => {
    const token = localStorage.getItem("authToken"); // Retrieve token from localStorage
    axios
      .delete(`https://api.carolesplacecafe.com/api/products/${productToDelete}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        fetchData();
        setOpenDeleteDialog(false);
        setProductToDelete(null);
      })
      .catch((error) => console.error("Error deleting product:", error));
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
    setProductToDelete(null);
  };

  const handleEditClick = (product) => {
    const latestPrice = product.price[Object.keys(product.price).sort().pop()];
    setCurrentProduct({ ...product, price: latestPrice });
    setOpenEditDialog(true);
  };

  const handleSaveEdit = () => {
    const token = localStorage.getItem("authToken"); // Retrieve token from localStorage
    axios
      .put(`https://api.carolesplacecafe.com/api/products/${currentProduct.name}`, currentProduct, {
        headers: { Authorization: token },
      })
      .then((response) => {
        fetchData();
        setOpenEditDialog(false);
      })
      .catch((error) => console.error("Error updating product:", error));
  };

  const handleCancelEdit = () => {
    setOpenEditDialog(false);
  };

  const handleAddProduct = () => {
    const token = localStorage.getItem("authToken"); // Retrieve token from localStorage
    const productData = {
      name: newProduct.name,
      price: newProduct.price,
      good_stock: newProduct.good_stock,
      current_stock: newProduct.current_stock,
    };

    axios
      .post("https://api.carolesplacecafe.com/api/products", productData, {
        headers: { Authorization: token },
      })
      .then((response) => {
        fetchData();
        setOpenAddDialog(false);
        setNewProduct({ name: "", price: 0, good_stock: 0, current_stock: 0 });
      })
      .catch((error) => console.error("Error adding product:", error));
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setNewProduct({ name: "", price: 0, good_stock: 0, current_stock: 0 });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Items Low On Stock"
              count={<CountUp end={lowStockItems} duration={1.5} />}
              icon={{
                color: "error",
                component: <i className="ni ni-fat-remove" />,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Replenish All Cost"
              count={<CountUp end={totalReplenishCost} duration={1.5} prefix="$" />}
              icon={{
                color: "warning",
                component: <i className="ni ni-money-coins" />,
              }}
            />
          </Grid>
        </Grid>

        <Card>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <ArgonTypography variant="h6">Products Table</ArgonTypography>
            <IconButton color="primary" onClick={handleOpenAddDialog}>
              <AddIcon />
            </IconButton>
          </ArgonBox>
          <ArgonBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            {loading ? (
              <ArgonBox display="flex" justifyContent="center" alignItems="center" py={5}>
                <CircularProgress />
              </ArgonBox>
            ) : (
              <Table columns={columns} rows={productRows} />
            )}
          </ArgonBox>
        </Card>
      </ArgonBox>
      <Footer />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Product"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {productToDelete}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Product Dialog */}
      <Dialog open={openEditDialog} onClose={handleCancelEdit} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Product</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Product Name"
            type="text"
            fullWidth
            value={currentProduct.name}
            onChange={(e) => setCurrentProduct({ ...currentProduct, name: e.target.value })}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Price"
            type="number"
            fullWidth
            value={currentProduct.price}
            onChange={(e) => setCurrentProduct({ ...currentProduct, price: parseFloat(e.target.value) })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Good Stock"
            type="number"
            fullWidth
            value={currentProduct.good_stock}
            onChange={(e) => setCurrentProduct({ ...currentProduct, good_stock: parseInt(e.target.value) })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Current Stock"
            type="number"
            fullWidth
            value={currentProduct.history[currentProduct.history.length - 1]?.quantity || 0}
            onChange={(e) => {
              const updatedHistory = [...currentProduct.history];
              updatedHistory[updatedHistory.length - 1].quantity = parseInt(e.target.value, 10);
              setCurrentProduct({ ...currentProduct, history: updatedHistory });
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEdit} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Product Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Product</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Product Name"
            type="text"
            fullWidth
            value={newProduct.name}
            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Price"
            type="number"
            fullWidth
            value={newProduct.price}
            onChange={(e) => setNewProduct({ ...newProduct, price: parseFloat(e.target.value) })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Good Stock"
            type="number"
            fullWidth
            value={newProduct.good_stock}
            onChange={(e) => setNewProduct({ ...newProduct, good_stock: parseInt(e.target.value) })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            label="Current Stock"
            type="number"
            fullWidth
            value={newProduct.current_stock}
            onChange={(e) => setNewProduct({ ...newProduct, current_stock: parseInt(e.target.value) })}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddProduct} color="primary">
            Add Product
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Products;
