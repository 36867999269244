import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ component: Component }) => {
  const token = localStorage.getItem('authToken');
  return token ? <Component /> : <Navigate to="/authentication/sign-in" />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default ProtectedRoute;