import React, { useState, useEffect } from "react";
import axios from "axios";
import CountUp from "react-countup";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";

function getRandomBrightColor() {
  const r = Math.floor(Math.random() * 128 + 128);
  const g = Math.floor(Math.random() * 128 + 128);
  const b = Math.floor(Math.random() * 128 + 128);
  return `rgba(${r},${g},${b},1)`;
}

function Default() {
  const [dashboardData, setDashboardData] = useState({
    stock_value: 0,
    total_items: 0,
    stock_fluctuation: [],
  });

  const [productHistoryData, setProductHistoryData] = useState({});
  const [loading, setLoading] = useState(true);
  const [chartReady, setChartReady] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken"); // Retrieve the token from localStorage
    
    // Fetch the dashboard data
    axios
      .get("https://api.carolesplacecafe.com/api/dashboard", {
        headers: { Authorization: token }, // Include the token in the request headers
      })
      .then((response) => {
        console.log("Dashboard API response:", response.data); // Debugging log
        setDashboardData(response.data);
        setLoading(false);
        // Delay chart rendering by 0.5 seconds to ensure everything is in place
        setTimeout(() => {
          setChartReady(true);
        }, 500);
      })
      .catch((error) => {
        console.error("Error fetching dashboard data:", error);
        setLoading(false);
      });

    // Fetch the product history data
    axios
      .get("https://api.carolesplacecafe.com/api/product-history", {
        headers: { Authorization: token }, // Include the token in the request headers
      })
      .then((response) => {
        console.log("Product History API response:", response.data); // Debugging log
        setProductHistoryData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product history data:", error);
      });
  }, []);

  // Calculate percentage change from yesterday
  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) {
      return 0;
    }
    return ((current - previous) / previous) * 100;
  };

  // Get the values for today and yesterday
  const todayTotalItems = dashboardData.total_items;
  const yesterdayTotalItems =
    dashboardData.stock_fluctuation.length > 1
      ? dashboardData.stock_fluctuation[
          dashboardData.stock_fluctuation.length - 2
        ].quantity
      : 0;

  // Calculate percentage change for the chart
  const totalItemsChange = calculatePercentageChange(
    todayTotalItems,
    yesterdayTotalItems
  );

  // Determine color and icon for the percentage change
  const getChangeColor = (change) =>
    change > 0 ? "success" : change < 0 ? "error" : "text";
  const getChangeIcon = (change) =>
    change > 0 ? "arrow_upward" : change < 0 ? "arrow_downward" : null;

  // Check if stock_fluctuation has data before proceeding
  const stockFluctuationData =
    dashboardData.stock_fluctuation.length > 0
      ? {
          labels: dashboardData.stock_fluctuation.map((entry) => entry.date),
          datasets: [
            {
              label: "Total Stock",
              data: dashboardData.stock_fluctuation.map(
                (entry) => entry.quantity
              ),
              borderColor: "rgba(75,192,192,1)",
              backgroundColor: "rgba(75,192,192,0.2)",
              fill: true,
            },
          ],
        }
      : null;

  // Create datasets for each product's fluctuation
  const productFluctuationData =
  Object.keys(productHistoryData).length > 0
    ? {
        labels: productHistoryData[
          Object.keys(productHistoryData)[0]
        ].map((entry) => entry.date),
        datasets: Object.keys(productHistoryData).map((productName) => {
          const color = getRandomBrightColor();
          return {
            label: productName,
            data: productHistoryData[productName].map((entry) => entry.quantity),
            borderColor: color,
            backgroundColor: color.replace(',1)', ',0.2)'),
            fill: true,
          };
        }),
      }
    : null;

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
      },
      x: {
        // Adjust x-axis settings if necessary
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 20,
        bottom: 10,
      },
    },
    elements: {
      line: {
        tension: 0.3, // Smooth out the curve
      },
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        {loading ? (
          <ArgonBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </ArgonBox>
        ) : (
          <>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={6} lg={3}>
                <DetailedStatisticsCard
                  title="Stock Value"
                  count={
                    <CountUp
                      end={dashboardData.stock_value}
                      duration={1.5}
                      prefix="$"
                    />
                  } // Animated count
                  icon={{
                    color: "info",
                    component: <i className="ni ni-money-coins" />,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <DetailedStatisticsCard
                  title="Total Stock Items"
                  count={
                    <CountUp end={dashboardData.total_items} duration={1.5} />
                  } // Animated count
                  icon={{
                    color: "error",
                    component: <i className="ni ni-world" />,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {chartReady && stockFluctuationData ? (
                  <GradientLineChart
                    title="Product Fluctuation"
                    description={
                      totalItemsChange !== 0 && (
                        <ArgonBox display="flex" alignItems="center">
                          <ArgonBox
                            fontSize="lg"
                            color={getChangeColor(totalItemsChange)}
                            mb={0.3}
                            mr={0.5}
                            lineHeight={0}
                          >
                            <Icon sx={{ fontWeight: "bold" }}>
                              {getChangeIcon(totalItemsChange)}
                            </Icon>
                          </ArgonBox>
                          <ArgonTypography
                            variant="button"
                            color="text"
                            fontWeight="medium"
                          >
                            {totalItemsChange >= 0
                              ? `+${totalItemsChange.toFixed(2)}`
                              : totalItemsChange.toFixed(2)}
                            % more{" "}
                            <ArgonTypography
                              variant="button"
                              color="text"
                              fontWeight="regular"
                            >
                              since yesterday
                            </ArgonTypography>
                          </ArgonTypography>
                        </ArgonBox>
                      )
                    }
                    chart={stockFluctuationData}
                    options={chartOptions}
                  />
                ) : (
                  <ArgonBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="300px"
                  >
                    <CircularProgress />
                    {chartReady && !stockFluctuationData && (
                      <ArgonTypography
                        variant="h6"
                        color="text"
                        fontWeight="medium"
                      >
                        No data available
                      </ArgonTypography>
                    )}
                  </ArgonBox>
                )}
              </Grid>
              <Grid item xs={12}>
                {chartReady && productFluctuationData ? (
                  <DefaultLineChart
                    title="Individual Product Fluctuations"
                    description="This chart shows the stock fluctuations for each product."
                    chart={productFluctuationData}
                    options={chartOptions}
                  />
                ) : (
                  <ArgonBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="300px"
                  >
                    <CircularProgress />
                    {chartReady && !productFluctuationData && (
                      <ArgonTypography
                        variant="h6"
                        color="text"
                        fontWeight="medium"
                      >
                        No data available
                      </ArgonTypography>
                    )}
                  </ArgonBox>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
